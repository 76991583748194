import {Outlet, Link} from "react-router-dom";
import Styles from "./styles/pr.module.css";
import Constitution from "./documents/Heartland Constitution - Revised 2021.pdf";
import TopLogo from "./images/heartland_logo_small_border_notag.jpg";
import {useEffect, useState, useContext} from "react";
import OneSermonView from "./one_sermon_view";
import React from "react";
import {load_fetch} from "./pr_utils";
import {AuthContext} from "./AuthContext";

const Layout = (props) => {
    const [mostRecentSermon, setMostRecentSermon] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [userInfo, setUserInfo] = useState(false);
    //const authContext = useContext(AuthContext);
    const {authStatus, setAuthStatus} = useContext(AuthContext);
    const [canViewDirectory, setCanViewDirectory] = useState(props.canViewDirectory);

    useEffect(() => {
        setUserInfo(authStatus);
    }, [authStatus])
    useEffect(() => {
        setCanViewDirectory(props.canViewDirectory);

    }, [props.canViewDirectory])

    // useEffect(() => {
    //     get_most_recent_sermon();
    // }, []);
    // const get_most_recent_sermon = () => {
    //     let url = 'Sermon_Handler.php';
    //     let postData = {
    //         operation: 'GET_MOST_RECENT_SERMON'
    //     }
    //     load_fetch(url, postData, (data) => {
    //         if(!data['ERROR'] && !data['error']) {
    //             setMostRecentSermon(data);
    //         } else {
    //             console.log(data);
    //         }
    //     });
    // }
    const clear_session_info = () => {
        let url = 'Security_Handler.php';
        let postData = {
            operation: 'CLEAR_SESSION_LOGOUT'
        }
        load_fetch(url, postData, (data) => {
            console.log(data);
            if(!data['ERROR'] && !data['error']) {
                console.log("Possible error logging out.");
            } else {
                console.log("Logged out.");
            }
        }, 'text');
    }
    function validate_email(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handle_login = () => {
        //Validate
        //if(!validate_email(loginEmail)) {
        if(loginEmail.length <= 0) {
            setLoginErrorMessage("You must enter a username or email");//"Invalid email address.");
            return;
        }
        if(loginPassword.trim().length <= 0) {
            setLoginErrorMessage("You must enter a password.");
            return;
        }
        let url = 'Security_Handler.php';
        let postData = {
            operation: 'PROCESS_LOGIN_ATTEMPT',
            email: loginEmail,
            password: loginPassword
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //Clear any error messages
                setLoginErrorMessage('');

                //Hide Login Form
                setShowLogin(false);

                //Clear the Login Form
                setLoginEmail('');
                setLoginPassword('');

                //Set the User Info
                setUserInfo(data);

                //Can view Directory?
                if(data['role'] === 'ADMIN' ||
                    data['role'] === 'DIRECTORY_EDITOR' ||
                    data['role'] === 'DIRECTORY_VIEWER') {
                    setCanViewDirectory(true);
                }

                //set Auth Status
                //TODO: Figure this out!
                //authContext.set(true);
                setAuthStatus(true);
                props.update_user_info(data);
            } else {
                setUserInfo(false);
                props.update_user_info(false);
                setLoginErrorMessage("Incorrect credentials.");
                console.log(data);
            }
        });
    }
    const logout = () => {
        setUserInfo(false);
        setCanViewDirectory(false);
        setAuthStatus(false);
        clear_session_info();
        props.app_logout_function();
    }

    // function updatemenu() {
    //     if (document.getElementById('responsive-menu').checked == true) {
    //         //document.getElementById('menu').style.borderBottomRightRadius = '0';
    //        // document.getElementById('menu').style.borderBottomLeftRadius = '0';
    //     } else {
    //        // document.getElementById('menu').style.borderRadius = '0px';
    //     }
    // }
    const prevent_click = (event) => {
        event.preventDefault();
    }

    /*
    <li><a className={Styles.dropdown_arrow} href='#'
                           onClick={(event) => prevent_click(event)}>News</a>
                        <ul className={Styles.sub_menus}>
                            <li><a href='news.html'>News</a></li>
                            <li><a href='calendar.html'>Calendar</a></li>
                        </ul>
                    </li>

                                                <li><a href='proq.html'>Prayer Requests or Questions</a></li>
     */

    const toggleShowLogin = (event) => {
        event.preventDefault();
        setShowLogin(!showLogin);
    }

    return (
        <div className={Styles.pr_wrapper}>
            <header className={Styles.pr_page_header}>
                <div className={Styles.pr_header_row_1}>
                    <img src={TopLogo} className={Styles.pr_top_logo} />
                    <button
                        className={userInfo ? Styles.pr_hide : Styles.pr_contact_button}
                        onClick={toggleShowLogin}
                    >
                        Sign In
                    </button>
                    <div className={userInfo ? '' : Styles.pr_hide}>
                        <span className={Styles.pr_welcome}>Welcome, {userInfo['full_name']}</span>
                        <button className={Styles.pr_contact_button} onClick={logout}>Log out</button>
                    </div>
                </div>
            </header>
            <div className={Styles.pr_nav_wrapper}>
                <nav id='menu' className={Styles.pr_menu}>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><a className={Styles.dropdown_arrow} href='#'
                               onClick={(event) => prevent_click(event)}>About Us</a>
                            <ul className={Styles.sub_menus}>
                                <li><Link to='about_us'>About Us</Link></li>
                                <li><Link to='statement_of_faith'>What we Believe</Link></li>
                                <li><a href={Constitution} target="_blank" rel="noreferrer">Constitution</a></li>
                                <li><Link to='leaders'>Association Board</Link></li>
                            </ul>
                        </li>
                        <li><a className={Styles.dropdown_arrow} href='#'
                               onClick={(event) => prevent_click(event)}>Churches</a>
                            <ul className={Styles.sub_menus}>
                                <li><Link to='bethel'>Bethel</Link></li>
                                <li><Link to='flack'>Flack Memorial</Link></li>
                                <li><Link to='hidden_valley'>Hidden Valley</Link></li>
                                <li><Link to='prairie_ridge'>Prairie Ridge</Link></li>
                                <li><Link to='richmond'>Richmond</Link></li>
                            </ul>
                        </li>
                        <li><a className={Styles.dropdown_arrow} href='ministries.html'
                               onClick={(event) => prevent_click(event)}>Sponsored Ministries</a>
                            <ul className={Styles.sub_menus}>
                                <li><Link target="_blank" to='https://www.mojuniorcamp.net/'>Missouri Junior Camp</Link></li>
                                <li><Link target="_blank" to='https://camptristate.com/'>Tri-State Camp</Link></li>
                                <li><Link target="_blank" to='https://newhopehaiti.com/'>New Hope Missions - Haiti</Link></li>
                                <li><Link target="_blank" to='https://bibleblessings.org/'>From Our Hands to Their Hands</Link></li>
                                <li><Link target="_blank" to='https://www.gideons.org/'>Gideons</Link></li>
                            </ul>
                        </li>
                        <li><a className={Styles.dropdown_arrow} href='ministries.html'
                               onClick={(event) => prevent_click(event)}>Resources</a>
                            <ul className={Styles.sub_menus}>
                                <li><Link to='find_a_church'>Find a Church</Link></li>
                                <li><Link to='joining_heartland'>Joining Heartland</Link></li>
                                <li><Link to='ministry_credentialing'>Ministry Credentialing</Link></li>
                            </ul>
                        </li>
                        {canViewDirectory ? (
                                <li><Link to="conference_signups">Conf. Signups</Link></li>
                            )
                            : ''}
                        <li><Link to='contact_us'>Contact Us</Link></li>
                    </ul>
                </nav>
            </div>
            <form>
                <div className={showLogin ? Styles.pr_login_div : Styles.pr_hide}>
                    <div>email:</div>
                    <div>
                        <input
                            type="text"
                            name="email"
                            autoComplete="email"
                            className={Styles.pr_login_input}
                            value={loginEmail}
                            onChange={(event) => setLoginEmail(event.target.value)}
                        /></div>
                    <div>password:</div>
                    <div>
                        <input
                            type="password"
                            name="password"
                        autoComplete="current-password"
                        className={Styles.pr_login_input}
                        value={loginPassword}
                        onChange={(event) => setLoginPassword(event.target.value)}
                    /></div>
                <div><button type="button" className={Styles.pr_button} onClick={handle_login}>Login</button></div>
            </div>
            </form>
            <div className={loginErrorMessage ? Styles.pr_login_error : Styles.pr_hide}>
                {loginErrorMessage}
            </div>

            <main className={Styles.pr_main_container}>
                <div className={Styles.pr_main_column}>
                    <div className={Styles.pr_outlet_holder}>
                        <Outlet />
                    </div>
                </div>
                <div className={Styles.pr_right_column}>
                </div>
            </main>
            <footer className={Styles.pr_footer}>
                <small>
                    &copy; 2024 Heartland Association of Churches
                </small>
                <ul>

                </ul>
            </footer>
        </div>
    )
};

export default Layout;