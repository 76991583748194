import React, { useState, useEffect } from "react";
import Styles from "./styles/pr.module.css";
import ConferenceStyles from "./styles/conference.module.css";
import { Link } from "react-router-dom";
import { talk } from "./pr_utils";

const ConferenceRegistration = (props) => {
    document.title = "HAOC - " + props.page_title;

    const [numAttendees, setNumAttendees] = useState(1);
    const [attendees, setAttendees] = useState([{ first_name: "", last_name: "", home_church: "", phone: "", email: "", breakfast: false }]);
    const [submitted, setSubmitted] = useState(false);
    const [captchaQuestion, setCaptchaQuestion] = useState("");
    const [captchaAnswer, setCaptchaAnswer] = useState("");
    const [userCaptchaInput, setUserCaptchaInput] = useState("");
    const [captchaError, setCaptchaError] = useState("");
    const [validationError, setValidationError] = useState("");
    const [confirmationData, setConfirmationData] = useState('');

    useEffect(() => {
        generate_captcha();
    }, []);

    const generate_captcha = () => {
        const num1 = Math.floor(Math.random() * 10) + 1;
        const num2 = Math.floor(Math.random() * 10) + 1;
        setCaptchaQuestion(`What is ${num1} + ${num2}? (answer in digits)`);
        setCaptchaAnswer((num1 + num2).toString());
    };

    const handle_num_change = (e) => {
        const count = parseInt(e.target.value) || 1;
        setNumAttendees(count);
        const new_attendees = Array.from({ length: count }, (_, i) => attendees[i] || { first_name: "", last_name: "", home_church: "", phone: "", email: "", breakfast: false });
        setAttendees(new_attendees);
    };

    const handle_input_change = (index, field, value) => {
        const updated_attendees = [...attendees];
        updated_attendees[index][field] = value;
        setAttendees(updated_attendees);
    };

    const handle_captcha_input = (e) => {
        setUserCaptchaInput(e.target.value);
    };

    const handle_submit = (e) => {
        e.preventDefault();

        // Validate phone or email for each attendee
        for (const attendee of attendees) {
            if (!attendee.phone && !attendee.email) {
                setValidationError("Each attendee must provide at least a phone number or an email address.");
                return;
            }
        }

        // Validate CAPTCHA
        if (userCaptchaInput !== captchaAnswer) {
            setCaptchaError("Incorrect CAPTCHA answer. Please try again.");
            generate_captcha();
            setUserCaptchaInput("");
            return;
        }

        setCaptchaError("");
        setValidationError("");

        //Prepare the data
        let postData = {
            attendees: attendees,
            captcha_passed: true
        }

        //Submit registration
        talk(
            "Conference_Handler.php",
            "INSERT_CONFERENCE_REGISTRATION",
            postData,
            submit_registration_handler
        );
    };
    const submit_registration_handler = (operation, sent_data, return_data) => {
        if(!return_data['error']) {
            console.log(return_data);
            setConfirmationData(return_data['CONFIRMATION_DATA']);
        }
        setSubmitted(true);
    }

    if (submitted) {
        return (
            <div className={ConferenceStyles.confirmation_message}>
                <h2>Thank you for registering!</h2>
                <p>Looking forward to seeing you in April.</p>
                <button
                    className={ConferenceStyles.submit_button}
                    onClick={() => {
                        setSubmitted(false);
                        setAttendees([{ first_name: "", last_name: "", home_church: "", phone: "", email: "", breakfast: false }]);
                        setNumAttendees(1);
                        setConfirmationData('');
                    }}
                >
                    Register Someone Else
                </button>
                <div className={ConferenceStyles.registration_summary}>
                    <h3>Registration Summary</h3>
                    <p><strong>Confirmation Code:</strong> {confirmationData['confirmation_number']}</p> {/* Replace with dynamically generated code */}
                    <ul>
                        {confirmationData['attendees'].map((attendee, index) => (
                            <li key={index}>
                                <p><strong>Attendee {index + 1}:</strong> {attendee.first_name} {attendee.last_name}</p>
                                <p><strong>Home Church:</strong> {attendee.home_church || "N/A"}</p>
                                <p><strong>Phone:</strong> {attendee.phone || "N/A"}</p>
                                <p><strong>Email:</strong> {attendee.email || "N/A"}</p>
                                <p><strong>Breakfast:</strong> {attendee.breakfast ? "Yes" : "No"}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }


    return (
        <div className={ConferenceStyles.registration_container}>
            <h1>Faithful and Flourishing</h1>
            <h3>Conference Registration</h3>
            <form onSubmit={handle_submit}>
                <div className={ConferenceStyles.form_row}>
                    <label>How many people will attend?&nbsp;
                        <input type="number" min="1" value={numAttendees} onChange={handle_num_change} />
                    </label>
                </div>
                {attendees.map((attendee, index) => (
                    <div key={index} className={ConferenceStyles.attendee_form}>
                        <h3>Attendee {index + 1}</h3>
                        <div className={ConferenceStyles.form_row}>
                            <input type="text" placeholder="First Name" value={attendee.first_name} onChange={(e) => handle_input_change(index, "first_name", e.target.value)} required />
                            <input type="text" placeholder="Last Name" value={attendee.last_name} onChange={(e) => handle_input_change(index, "last_name", e.target.value)} required />
                            <input type="text" placeholder="Home Church (Optional)" value={attendee.home_church} onChange={(e) => handle_input_change(index, "home_church", e.target.value)} />
                        </div>
                        <div className={ConferenceStyles.form_row}>
                            <input type="tel" placeholder="Phone Number" value={attendee.phone} onChange={(e) => handle_input_change(index, "phone", e.target.value)} />
                            <input type="email" placeholder="Email Address" value={attendee.email} onChange={(e) => handle_input_change(index, "email", e.target.value)} />
                            <label>
                                <input type="checkbox" checked={attendee.breakfast} onChange={(e) => handle_input_change(index, "breakfast", e.target.checked)} /> Attend Breakfast
                            </label>
                        </div>
                    </div>
                ))}
                {validationError && <p className={ConferenceStyles.validation_error}>{validationError}</p>}
                <div className={ConferenceStyles.form_row}>
                    <label>{captchaQuestion}</label>
                    <input type="text" value={userCaptchaInput} onChange={handle_captcha_input} required />
                    {captchaError && <p className={ConferenceStyles.captcha_error}>{captchaError}</p>}
                </div>
                <button type="submit" className={ConferenceStyles.submit_button}>Submit Registration</button>
            </form>
        </div>
    );
};

export default ConferenceRegistration;
