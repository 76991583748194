import React, {useEffect, useState} from "react";
import ConferenceStyles from "./styles/conference.module.css";
import {talk} from "./pr_utils";


const ConferenceSignups = (props) => {
    console.log(props);

    document.title = "HAOC - Conference Signups";

    const [attendees, setAttendees] = useState('');

    useEffect(() => {
        load_conference_attendee_data();
    }, []);

    const load_conference_attendee_data = () => {
        talk(
            "Conference_Handler.php",
            "GET_CONFERENCE_ATTENDEES",
            null,
            load_conference_attendee_data_handler
        )
    }
    const load_conference_attendee_data_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        setAttendees(return_data);
    }

    if(!props.authorized) {
        return <div className={ConferenceStyles.registration_container}>Unauthorized Access</div>
    }

    return (
        <div className={ConferenceStyles.registration_container}>
            <h1>Conference Signups</h1>

            {attendees ?
                <table className={ConferenceStyles.registration_table}>
                    <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Home Church</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Breakfast</th>
                    </tr>
                    </thead>
                    <tbody>
                    {attendees && attendees.map((attendee, index) => (
                        <tr key={index}>
                            <td>{attendee.first_name.trim()}</td>
                            <td>{attendee.last_name.trim()}</td>
                            <td>{attendee.home_church.trim() || "N/A"}</td>
                            <td>{attendee.phone.trim() || "N/A"}</td>
                            <td>{attendee.email.trim() || "N/A"}</td>
                            <td>{attendee.breakfast.trim() ? "Yes" : "No"}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                : <div>No registrations yet</div>}
        </div>
    )
};

export default ConferenceSignups;
